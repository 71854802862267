import logo from './logo.svg';
import './App.css';
// import './script.js';

function App() {
  return (
    <>
    <header>
        <a href="#" className="logo"><span>A</span>NURAG <span>B</span>HAGAT </a>
        <ul className="navlist">
          <li><a href="#home" className="active">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#portfolio">Portfolio</a></li>
          <li><a href="#blog">Blog</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
        <div className="right-header">
          <a href="#" className="btn">Let's chat <i className='bx bx-message-dots' ></i></a>
          <div className="menu-icon">
            <div className="bar"></div>
          </div>
        </div>
      </header>

      <section className="home" id="home">
        <div className="hero-info">
          <h3>Welcome To my World</h3>
          <h1>Hi I'm Anurag</h1>

          <div className="text-animate">
            <h2>Full Stack Developer</h2>
          </div>

          <p>I thrive on creating dynamic websites and innovative software solutions. With a passion for coding and problem-solving, I bring your digital visions to life with precision and creativity.</p>

          <div className="btn-box">
            <a href="mailto:richard@mail.com" className="btn">Hire Me Now ! <i className='bx bx-right-arrow-alt' ></i></a>
            <a href="img/resume.pdf" target="_blank" className="btn d-CV">Download CV <i className='bx bx-download'></i></a>
          </div>

          <div className="social-media">
            <div className="bg-icon">
              <a href="#"><i className='bx bxl-instagram'></i></a>
              <span></span>
            </div>

            <div className="bg-icon">
              <a href="#"><i className='bx bxl-github'></i></a>
              <span></span>
            </div>

            <div className="bg-icon">
              <a href="#"><i className='bx bxl-twitter'></i></a>
              <span></span>
            </div>

            <div className="bg-icon">
              <a href="#"><i className='bx bxl-facebook'></i></a>
              <span></span>
            </div>
          </div>
        </div>
        <div className="img-hero">
          <img src="img/hero.png" alt="" />
          <div className="rotate-text">
            <div className="text">
              <p>I'm Web Developer -  e-commerce specialist  -  Designer</p>
            </div>
            <span><i></i></span>
          </div>
        </div>
      </section>

      <section className="about" id="about">
        <div className="about-img">
          <img src="img/aboutMe.png" alt="" className="aboutHero" />
          <div className="showcase-ring">
            <img src="shapes/ring.png" className="ring" />
            <img src="shapes/circle.png" className="circle" />
          </div>
        </div>
        <div className="about-content">
          <h2 className="heading">About Me</h2>
          <h3>2+ Year's Experience on Web Development</h3>
          <p>I have spent over two years building responsive, user-friendly websites and web applications. From front-end design to back-end development, I have honed my skills in creating seamless and efficient digital experiences, always focusing on performance and scalability.</p>
          <div className="about-btn">
            <button className="active">Main Skills</button>
            <button>Growth</button>
            <button>Education</button>
          </div>
          <div className="content-btn">

          <div className="content">
  <div className="text-box">
    <p>Next.js & React.js Development</p>
    <span>Building dynamic and high-performance web apps with Next.js and React.js.</span>
  </div>
  <div className="text-box">
    <p>Responsive Web Design</p>
    <span>Creating mobile-friendly, responsive websites using Tailwind CSS, Bootstrap, CSS, and HTML.</span>
  </div>
  <div className="text-box">
    <p>JavaScript Development</p>
    <span>Enhancing user interaction and functionality with JavaScript.</span>
  </div>
          </div>

          <div className="content">
              <div className="text-box">
                <p>Passion for Web Design</p>
                <span>Dedicated to creating visually appealing and user-friendly websites.</span>
              </div>
              <div className="text-box">
                <p>Continuous Learning & Growth</p>
                <span>Constantly improving my coding skills and keeping up with the latest technologies.</span>
              </div>
              <div className="text-box">
                <p>Project Contributions</p>
                <span>Contributing to open-source projects and collaborating with other developers to build great products.</span>
              </div>
            </div>

            <div className="content">
              <div className="text-box">
                <p>Online Courses and Bootcamps</p>
                <span>UDEMY , COURSEA(2021)</span>
              </div>
              <div className="text-box">
                <p>Internships and Work Experience</p>
                <span>O7 Solutions Jalandhar , INTERNSHALA(2022)</span>
              </div>
              <div className="text-box">
                <p>Bachelor's Degree in Electronics and communication</p>
                <span>DAVIET,Jalandhar (2017-2021)</span>
              </div>
            </div>

          </div>
          <div className="cvContent">
            <a href="img/resume.pdf" target="_blank" className="btn d-CV">Download CV <i className='bx bx-download'></i></a>
          </div>
        </div>
      </section>


      <section className="services" id="services">
        <div className="main-text">
          <h2 className="heading">My Services</h2>
          <span>What I will do for you</span>
        </div>

        <div className="allServices">
          <div className="servicesItem">
            <div className="icon-services">
              <i className='bx bx-layer'></i>
              <span></span>
            </div>
            <h3>App Development</h3>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iure inventore ea nemo ab recusandae modi voluptates maxime ipsam eveniet. Facilis.</p>
            <a href="#" className="readMore">Read More</a>
          </div>

          <div className="servicesItem">
            <div className="icon-services">
              <i className='bx bx-code-alt'></i>
              <span></span>
            </div>
            <h3>Web Development</h3>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iure inventore ea nemo ab recusandae modi voluptates maxime ipsam eveniet. Facilis.</p>
            <a href="#" className="readMore">Read More</a>
          </div>

          <div className="servicesItem">
            <div className="icon-services">
              <i className='bx bx-desktop'></i>
              <span></span>
            </div>
            <h3>UI / UX Design</h3>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iure inventore ea nemo ab recusandae modi voluptates maxime ipsam eveniet. Facilis.</p>
            <a href="#" className="readMore">Read More</a>
          </div>

          <div className="servicesItem">
            <div className="icon-services">
              <i className='bx bxs-party'></i>
              <span></span>
            </div>
            <h3>Digital Marketing</h3>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iure inventore ea nemo ab recusandae modi voluptates maxime ipsam eveniet. Facilis.</p>
            <a href="#" className="readMore">Read More</a>
          </div>
        </div>

        <div className="proposal">
          <div className="text-box">
            <span>Get In Touch</span>
            <h3>Have a Project On Your Mind</h3>
            <a href="#contact" className="btn">Contact Me</a>
          </div>
          <img src="img/support.png" className="first" alt="Support" />
        </div>

        <div className="showcase">
          <img src="shapes/ring.png" className="ring" alt="Ring" />
          <img src="shapes/circle.png" className="circle" alt="Circle" />
          <img src="shapes/circle.png" className="circle2" alt="Circle 2" />
          <img src="shapes/circle.png" className="circle3" alt="Circle 3" />
          <img src="shapes/half-ring.png" className="half-ring" alt="Half Ring" />
        </div>
      </section>

      <section className="portfolio" id="portfolio">
        <div className="main-text">
          <h2 className="heading">My Portfolio</h2>
          <span>What I have done for you</span>
        </div>

        <div className="fillter-buttons">
          <button className="button mixitup-control-active" data-filter="all">All Work</button>
          <button className="button" data-filter=".web">Web Development</button>
          <button className="button" data-filter=".uiux">UI/UX Design</button>
          <button className="button" data-filter=".branding">Branding Design</button>
        </div>

        <div className="portfolio-gallery">
          <div className="portfolio-box mix uiux">
            <div className="portfolio-content">
              <h3>UI/UX Design</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, soluta.</p>
              <a href="#" className="readMore">Explore More</a>
            </div>
            <div className="portfolio-img">
              <img src="img/portfolio/1.jpg" alt="Portfolio 1" />
            </div>
          </div>

          <div className="portfolio-box mix web">
            <div className="portfolio-content">
              <h3>Web Design</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, soluta.</p>
              <a href="#" className="readMore">Explore More</a>
            </div>
            <div className="portfolio-img">
              <img src="img/portfolio/2.jpg" alt="Portfolio 2" />
            </div>
          </div>

          <div className="portfolio-box mix web">
            <div className="portfolio-content">
              <h3>Web Development</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, soluta.</p>
              <a href="#" className="readMore">Explore More</a>
            </div>
            <div className="portfolio-img">
              <img src="img/portfolio/3.jpg" alt="Portfolio 3" />
            </div>
          </div>

          <div className="portfolio-box mix web">
            <div className="portfolio-content">
              <h3>Web Development</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, soluta.</p>
              <a href="#" className="readMore">Explore More</a>
            </div>
            <div className="portfolio-img">
              <img src="img/portfolio/4.jpg" alt="Portfolio 4" />
            </div>
          </div>

          <div className="portfolio-box mix uiux">
            <div className="portfolio-content">
              <h3>UI/UX Design</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, soluta.</p>
              <a href="#" className="readMore">Explore More</a>
            </div>
            <div className="portfolio-img">
              <img src="img/portfolio/5.jpg" alt="Portfolio 5" />
            </div>
          </div>

          <div className="portfolio-box mix branding">
            <div className="portfolio-content">
              <h3>Web Development</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, soluta.</p>
              <a href="#" className="readMore">Explore More</a>
            </div>
            <div className="portfolio-img">
              <img src="img/portfolio/6.jpg" alt="Portfolio 6" />
            </div>
          </div>
        </div>
      </section>






{/* BOLG SECTION */}


      {/* <section className="blog" id="blog">
        <div className="main-text">
          <h2 className="heading">Blog</h2>
          <span>Latest News & Post</span>
        </div>

        <div className="blog-box swiper mySwiper">
          <div className="cards swiper-wrapper">
            <div className="card swiper-slide">
              <div className="card-top">
                <img src="img/blog/1.jpg" alt="" />
              </div>
              <div className="card-info">
                <h2>Mobile App Landing Page</h2>
                <span className="date">Sunday, Jan 14, 2023</span>
                <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, illo.</p>
                <a href="#" className="readMore">Explore More</a>
              </div>
            </div>

            <div className="card swiper-slide">
              <div className="card-top">
                <img src="img/blog/2.jpg" alt="" />
              </div>
              <div className="card-info">
                <h2>Mobile App Landing Page</h2>
                <span className="date">Sunday, Jan 14, 2023</span>
                <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, illo.</p>
                <a href="#" className="readMore">Explore More</a>
              </div>
            </div>

            <div className="card swiper-slide">
              <div className="card-top">
                <img src="img/blog/3.jpg" alt="" />
              </div>
              <div className="card-info">
                <h2>Mobile App Landing Page</h2>
                <span className="date">Sunday, Jan 14, 2023</span>
                <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, illo.</p>
                <a href="#" className="readMore">Explore More</a>
              </div>
            </div>

            <div className="card swiper-slide">
              <div className="card-top">
                <img src="img/blog/4.jpg" alt="" />
              </div>
              <div className="card-info">
                <h2>Mobile App Landing Page</h2>
                <span className="date">Sunday, Jan 14, 2023</span>
                <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, illo.</p>
                <a href="#" className="readMore">Explore More</a>
              </div>
            </div>

            <div className="card swiper-slide">
              <div className="card-top">
                <img src="img/blog/5.jpg" alt="" />
              </div>
              <div className="card-info">
                <h2>Mobile App Landing Page</h2>
                <span className="date">Sunday, Jan 14, 2023</span>
                <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, illo.</p>
                <a href="#" className="readMore">Explore More</a>
              </div>
            </div>

            <div className="card swiper-slide">
              <div className="card-top">
                <img src="img/blog/6.jpg" alt="" />
              </div>
              <div className="card-info">
                <h2>Mobile App Landing Page</h2>
                <span className="date">Sunday, Jan 14, 2023</span>
                <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, illo.</p>
                <a href="#" className="readMore">Explore More</a>
              </div>
            </div>
          </div>
        </div>

        <div className="swiper-pagination"></div>

        <div className="showcase">
          <img src="shapes/ring.png" className="ring" alt="" />
          <img src="shapes/second-circle.png" className="second-circle" alt="" />
          <img src="shapes/circle.png" className="circle" alt="" />
          <img src="shapes/half-ring.png" className="half-ring" alt="" />
        </div>
      </section> */}

      <section className="down-box" id="contact">
        <div className="contactSkills">
          <div className="contact-info">
            <div className="main-text">
              <h2 className="heading">Contact Me</h2>
              <span>Get in touch with me</span>
            </div>
            <form action="#">
              <div className="input-box">
                <input type="text" placeholder="First Name" />
                <input type="text" placeholder="Last Name" />
              </div>
              <input type="email" placeholder="Email" />
              <input type="text" placeholder="Subject" />
              <textarea name="#" id="" cols="30" rows="10"></textarea>
              <div className="formBtn">
                <button type="submit" className="btn">Send Message</button>
              </div>
            </form>
          </div>
          <div className="skills">
            <div className="container">
              <div className="skillBox">
                <div className="main-text">
                  <h2 className="heading">My Skills</h2>
                  <span>Let Me Help You</span>
                </div>
                <div className="skill-wrap">
                  <div className="skill">
                    <div className="outer-circle">
                      <div className="inner-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="180px" height="180px">
                          <defs>
                            <linearGradient id="GradientColor">
                              <stop offset="0%" stopColor="#e91e63" />
                              <stop offset="100%" stopColor="#673ab7" />
                            </linearGradient>
                          </defs>
                          <circle cx="85" cy="85" r="75" strokeLinecap="round" />
                        </svg>
                        <h2 className="counter"><span data-target="89">0</span>%</h2>
                      </div>
                    </div>
                    <div className="sk-title">HTML</div>
                  </div>

                  <div className="skill">
                    <div className="outer-circle">
                      <div className="inner-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="180px" height="180px">
                          <defs>
                            <linearGradient id="GradientColor">
                              <stop offset="0%" stopColor="#e91e63" />
                              <stop offset="100%" stopColor="#673ab7" />
                            </linearGradient>
                          </defs>
                          <circle cx="85" cy="85" r="75" strokeLinecap="round" />
                        </svg>
                        <h2 className="counter"><span data-target="47">0</span>%</h2>
                      </div>
                    </div>
                    <div className="sk-title">CSS</div>
                  </div>

                  <div className="skill">
                    <div className="outer-circle">
                      <div className="inner-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="180px" height="180px">
                          <defs>
                            <linearGradient id="GradientColor">
                              <stop offset="0%" stopColor="#e91e63" />
                              <stop offset="100%" stopColor="#673ab7" />
                            </linearGradient>
                          </defs>
                          <circle cx="85" cy="85" r="75" strokeLinecap="round" />
                        </svg>
                        <h2 className="counter"><span data-target="56">0</span>%</h2>
                      </div>
                    </div>
                    <div className="sk-title">JavaScript</div>
                  </div>

                  <div className="skill">
                    <div className="outer-circle">
                      <div className="inner-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="180px" height="180px">
                          <defs>
                            <linearGradient id="GradientColor">
                              <stop offset="0%" stopColor="#e91e63" />
                              <stop offset="100%" stopColor="#673ab7" />
                            </linearGradient>
                          </defs>
                          <circle cx="85" cy="85" r="75" strokeLinecap="round" />
                        </svg>
                        <h2 className="counter"><span data-target="19">0</span>%</h2>
                      </div>
                    </div>
                    <div className="sk-title">UI/UX Design</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <p>Copyright © 2023 by <span>ANURAGCODES</span> || All Rights Reserved.</p>
      </footer>

      <div id="progress">
        <span id="progress-value">
          <i className="bx bxs-chevrons-up"></i>
        </span>
      </div>







      
    </>
  );
};

export default App;
